// @mui material components

import { TabPanel } from '@mui/lab';
import TabList from "@mui/lab/TabList";
import TabContext from '@mui/lab/TabContext';
import Container from "@mui/material/Container";
import React, { useState, useRef, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import InternalConsistency from 'tabs/InternalConsistency';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

// Images
import bgImage from "assets/images/bg-new.jpg";
import axios from 'axios';
import MKButton from "components/MKButton";
import { BiSolidAnalyse } from "react-icons/bi";
import { Grid as Gridx } from 'react-loader-spinner'
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKInput from "components/MKInput";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

function Dev() {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabType = (event, newValue) => setActiveTab(newValue);
  const [loadedPdf, setLoadedPDF] = useState('');
  const [processingProgress, setProcessingProgress] = useState(0.50);
  const [uploadingBtnText, setUploadingBtnText] = useState('New Analysis');
  const [currentlyProcessing, setCurrentlyProcessing] = useState(true);
  const [loadedBoundingBoxes, setLoadedBoundingBoxes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [gptInput, setgptInput] = useState('');
  const [allMessages, setAllMessages] = useState([]);
  const [activegptInput, setActivegptInput] = useState('');
  const [activeGPTOutput, setActiveGPTOutput] = useState('');
  const [activeGPTTitle, setActiveGPTTitle] = useState('');
  const [waitingForID, setWaitingForId] = useState('');
  const domain = window.location.hostname;

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  useEffect(() => {
    console.log("YAY1: ", localStorage.getItem('uploadId'));

    if (localStorage.getItem('userId')) {
      const userId = localStorage.getItem('userId');
        axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/all-gpt-entries' : '/alphax/all-gpt-entries', {userId})
        .then((response) => {
          console.log("Progress: ", response.data);
          if (response.data.ok) {
            setAllMessages(response.data.allData);

            //localStorage.setItem('uploadId', response.data.uploadId);
            //setCurrentlyProcessing(true);
            //setSelectedFile(event.target.files[0]);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }


    if (localStorage.getItem('uploadId')) {
      //console.log("YAY2");
      //setSelectedFile("file");
      //checkFileStatus();
    } else {
      //localStorage.setItem('uploadId', "6757a58a-07a0-4e99-aa3e-84e1b1327c02");
      //setSelectedFile("file");
      //checkFileStatus();
      //ddcf9c67-c102-48bd-8b2c-7959a49fc234
    }

  }, []);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const checkFileStatus = () => {
    const uploadId = localStorage.getItem('uploadId');
    console.log("uploadId: ", uploadId)
    if (uploadId) {
      const domain = window.location.hostname;
        console.log("Current domain:", domain);
        if (domain === "localhost") {
          axios.post('https://dev.zencheck.ai/alphax/status-x', {uploadId})
      .then((response) => {
        console.log("Progress: ",response.data);

        if (response.data.allData) {
          setLoadedBoundingBoxes(response.data.allData);
          setCurrentlyProcessing(false);
        } else {
          setTimeout(() => {
            if (response.data.progress) {
              setProcessingProgress(Number(response.data.progress).toFixed(2));
            }
            checkFileStatus();
          }, 500);
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
        } else {
          axios.post('https://dev.zencheck.ai/alphax/status', {uploadId})
      .then((response) => {
        console.log("Progress: ",response.data);
        if (response.data.ok) {
          
          //localStorage.setItem('uploadId', response.data.uploadId);
          //setCurrentlyProcessing(true);
          //setSelectedFile(event.target.files[0]);
        }

        if (response.data.allData) {
          setLoadedBoundingBoxes(response.data.allData);
          setCurrentlyProcessing(false);
        } else {
          setTimeout(() => {
            if (response.data.progress) {
              setProcessingProgress(Number(response.data.progress).toFixed(2));
            }
            checkFileStatus();
          }, 500);
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
        }
      
    } else {
      setTimeout(() => {
        checkFileStatus();
      }, 500);
    }
  }

  const checkPromptStatus = () => {
    const tokenId = localStorage.getItem('tokenId');
    const userId = localStorage.getItem('userId');
    console.log("tokenId: ", tokenId)
    if (tokenId) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/prompt-status' : '/alphax/prompt-status', {tokenId, userId})
      .then((response) => {
        if (response.data.ok) {
          console.log("response.data.results: ",response.data.results);
          setWaitingForId("");
          setgptInput("")
          const allNewMessages = allMessages;
          allNewMessages.push(response.data.results);
          setActiveGPTTitle(response.data.results.title); setActiveGPTOutput(response.data.results.reply); setActivegptInput(response.data.results.input); toggleModal()
          setAllMessages(allNewMessages);
        } else {
          setTimeout(() => {
            checkPromptStatus();
          }, 500);
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setTimeout(() => {
        checkFileStatus();
      }, 500);
    }
  }

  const sendPompt = () => {
    const userId = localStorage.getItem('userId');
    setWaitingForId("waiting");
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/send-prompt' : '/alphax/send-prompt', {gptInput, userId})
      .then((response) => {
        console.log("Progress: ", response.data);
        if (response.data.ok) {

          //localStorage.setItem('uploadId', response.data.uploadId);
          //setCurrentlyProcessing(true);
          //setSelectedFile(event.target.files[0]);
        }

        if (response.data.tokenId) {
          localStorage.setItem('tokenId', response.data.tokenId);
          setWaitingForId(response.data.token);
          setTimeout(() => {
            checkPromptStatus();
          }, 500);
        }


      })
      .catch((error) => {
        console.log(error);
      });
  }


  const handleFile = (fileUploaded) => {
    localStorage.removeItem('uploadId');
    const formData = new FormData();
    formData.append('pdf', fileUploaded);
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/analyse' : '/alphax/analyse', formData, {onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
    const progress = (loaded / total) * 100;
    console.log(`Upload Progress: ${progress.toFixed(2)}%`);
    if (progress < 99.9) {
      setUploadingBtnText(`Uploading... (${progress.toFixed(2)}%)`);
    } else {
      setUploadingBtnText(`New Analysis`);
      setCurrentlyProcessing(true);
      setSelectedFile(fileUploaded);
      setTimeout(() => {
        checkFileStatus();
      }, 500);
    }
    }})
      .then((response) => {
        if (response.data.ok) {
          localStorage.setItem('uploadId', response.data.uploadId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };

  console.log("allMessages: ", allMessages)
  console.log("activeTab: ", activeTab)

  return (
    <>
      <MKBox
        minHeight="7em"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
      </MKBox>
      <div id="header-buttons">
        <input
          type="file"
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }} />
      </div>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {
          selectedFile === null ?
            <div>
              <MKBox component="section" py={12}>
                <Container>
                  <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
                    <MKTypography variant="h3" mb={1}>
                      Prompt Engineering Mode (PEM)
                    </MKTypography>
                  </Grid>
                  <Grid style={{ maxHeight: "300px", overflow: "scroll" }} container item xs={12} lg={7} sx={{ mx: "200px" }}>
                    {
                      allMessages.map(({ title, input, reply }) => (
                        <MKButton variant="gradient" style={{ marginRight: "10px", marginBottom: "10px" }} color="info" onClick={() => { setActiveGPTTitle(title); setActiveGPTOutput(reply); setActivegptInput(input); toggleModal() }}>
                          {title}
                        </MKButton>
                      ))
                    }

                  </Grid>
                  <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                    <MKBox width="100%" component="form" method="post" autoComplete="off">
                      <MKBox p={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MKInput variant="standard" label="Your Message" disabled={waitingForID !== ""} value={gptInput} onChange={(e) => setgptInput(e.target.value)} multiline fullWidth rows={6} />
                          </Grid>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} my={2}>
                          <MKButton disabled={waitingForID !== ""} variant="gradient" color="dark" onClick={() => sendPompt()} fullWidth>
                            {waitingForID !== "" ? "waiting for response" : "Send Message"}
                          </MKButton>
                        </Grid>
                      </MKBox>
                    </MKBox>
                  </Grid>
                </Container>
              </MKBox>

            </div>
            : currentlyProcessing ?
              <div id="processing-loading-parent">
                <Gridx
                  visible={true}
                  height="150"
                  width="150"
                  color="#4fa94d"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper"
                />
                <p style={{ marginTop: "1em", opacity: 0.7 }}>Currently analysing your file.</p>
                <h1>{processingProgress}%</h1>
              </div>
              :
              <Grid item xs={12} lg={3}>
                <AppBar position="static">
                  <Tabs
                    display="flex"

                    alignItems="center"
                    flexDirection="row"
                    //  style={{marginBottom: "1em"}} 
                    value={activeTab}
                    onChange={handleTabType}>
                    <Tab label="Internal Consistency" />
                    <Tab label="Factual Accuracy" />
                    <Tab label="Version Comparison" />
                    <Tab label="Unusual Trends" />
                  </Tabs>
                  <InternalConsistency areas={loadedBoundingBoxes} firstPdf={domain === "localhost" ? `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf` : `/alphax/${localStorage.getItem('uploadId')}/pdf`} secondPdf={domain === "localhost" ? `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf` : `/alphax/${localStorage.getItem('uploadId')}/pdf`} />
                </AppBar>
              </Grid>
        }
      </Card>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{activeGPTTitle}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                <b>GPT:</b>:
                <p style={{ maxHeight: "150px", overflow: "scroll" }}>{activegptInput}</p>
                <br />
                <b>You:</b>
                <p style={{ maxHeight: "250px", overflow: "scroll" }}>{activeGPTOutput}</p>
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </>
  );
}

export default Dev;
