import React, { useState, useRef, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Tab as Tabx, Tabs as Tbsx, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InternalConsistency from './tabs/InternalConsistency';
import type { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import axios , { AxiosHeaderValue} from 'axios';
import { Grid } from 'react-loader-spinner'
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import View from "layouts/sections/components/View";
import MKBox from "components/MKBox";
import TabsSimple from "layouts/sections/navigation/nav-tabs/components/TabsSimple";
import Presentation from "pages/Presentation";
import Dev from "pages/Presentation copy";
import SignInBasic from "pages/LandingPages/SignIn";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
const domain = window.location.hostname;
/*
import posthog from 'posthog-js'

const domain = window.location.hostname;

function generateSessionId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
  const randomNum = Math.random().toString(36).substring(2); // Generate a random base-36 string
  return `${timestamp}-${randomNum}`;
}

const sessionId = generateSessionId();

console.log({sessionId})

if (process.env.NODE_ENV === 'production' && (window as any).profileStatus && (window as any).profileStatus.email && domain !== "dev.zencheck.ai") {
  console.log("tr a")
  posthog.init('phc_q7zOxELmWtcCgNeH8h1dFOHWtuvHYIIppPNH14OCmXO',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
        autocapture: true,
        session_recording: {
          maskAllInputs: false,
        },
        bootstrap: {
          sessionID: sessionId
        }
        
    }
)

if ((window as any).profileStatus.email) {
  console.log("tr b")
  const userId = (window as any).profileStatus.email;
  if (userId) {
    console.log("tr c")
    posthog.identify(userId);

    posthog.people.set({
      email: userId
    });
  }
  

  
}
}
*/

/*
if (process.env.NODE_ENV === 'production' && domain !== "dev.zencheck.ai") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}*/


try {
  localStorage.setItem('testing-storage', "test-string");
} catch (e) {
  //localStorage.clear();
}


const areas: HighlightArea[]=[
    {
        pageIndex: 3,
        height: 1.55401,
        width: 28.1674,
        left: 27.5399,
        top: 15.0772,
    },
    {
        pageIndex: 3,
        height: 1.32637,
        width: 37.477,
        left: 55.7062,
        top: 15.2715,
    },
    {
        pageIndex: 3,
        height: 1.55401,
        width: 28.7437,
        left: 16.3638,
        top: 16.6616,
    },
];


const pdfData:any = localStorage.getItem('localPDFCacheData');
let localPDFCacheData:any = localStorage.getItem('localPDFCacheData') ? JSON.parse(pdfData) : {};
let localPDFCache:any = {};
console.log({localPDFCacheData})


Object.keys(localPDFCacheData).forEach(id => {
  if (localStorage.getItem(`pdf-data-${id}`)) {
    const pdfData:any = localStorage.getItem(`pdf-data-${id}`);
      const url = pdfData;
      localPDFCache[id] = url;
    
  }
  
});

//
let localThumbnailCache:any = {}
let localThumbnailCacheData = localStorage.getItem('localThumbnailCacheData') ? JSON.parse(String(localStorage.getItem('localThumbnailCacheData'))) : {};

Object.keys(localThumbnailCacheData).forEach(id => {
  if (localStorage.getItem(`preview-data-${id}`)) {
    const previewData:any = localStorage.getItem(`preview-data-${id}`);
      const url = previewData;
      localThumbnailCache[id] = url;
    
  }
  
});
let localBoundingBoxesData:any = {};
function App() {
  const [loadedPdf, setLoadedPDF] = useState('');
  const [currentlyProcessing, setCurrentlyProcessing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const hiddenFileInput = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem('rememberMe') ? (window as any).profileStatus?.email : null)
  const [userToken, setUserToken] = useState(null)
  const [password, setPassword] = useState("")
  const [isSignedIn, setIsSignedIn] = useState((window as any).profileStatus && localStorage.getItem('rememberMe') ? true : false)
  const [signInError, setSignInError] = useState(false)
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [checkedLogin, setCheckedLogin] = useState(false)
  const [cachedPDFs, setCachedPDFs] = useState({})
  const [cachedBoundingBoxesData, setCachedBoundingBoxesData] = useState({})
  const [loadedInitialData, setLoadedInitialData] = useState(false)
  const domain = window.location.hostname;

  useEffect(() => {
    checkUserProfile();
    localStorage.removeItem('uploadId')
    const token = localStorage.getItem('token');
    if ((window as any).profileStatus && token) {
      getUserProfile(token);
      //setIsSignedIn(true);
    }

    

    


  }, []); 
  

  
  


  const handleTabType = (event:any, newValue:number) => setActiveTab(newValue);

  const handleFileUpload = (event:any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('pdf', selectedFile);
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/analyse' : '/alphax/analyse', formData)
      .then((response) => {
        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPDFWithAxios = (url:string, callback:any) => {
    axios.get(url, {
        responseType: 'blob'  // Important to handle PDF files
    })
    .then(response => {
        callback(response.data);  // response.data is a Blob object
    })
    .catch(error => {
        console.error('Error fetching PDF:', error);
    });
}

// Function to convert a Blob to a Base64 string
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
};

const imageToBase64 = (url: string, callback: (base64: string | null) => void): void => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
          callback(reader.result as string);
      };
      reader.readAsDataURL(xhr.response as Blob);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}






const getProcessedData = (uploadId:string) => {
  //const domain = window.location.hostname;
      if (domain === "localhost") {
        axios.post('https://dev.zencheck.ai/alphax/status-x', { uploadId, token: "" })
          .then((response) => {

            if (response.data.allData) {
              localBoundingBoxesData[uploadId] = response.data;
              localBoundingBoxesData[uploadId].profileData.uploaded.reverse();
              setCachedBoundingBoxesData(localBoundingBoxesData);
            }

          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              // Handle 403 Forbidden error
              console.error('Forbidden: Access to this resource is denied');
            } else {
              // Handle other errors
              console.error(error.message);
            }
          });
      } else {

        if ((window as any).profileStatus && (window as any).profileStatus.csrfToken) {
          const csrfToken: AxiosHeaderValue = (window as any).profileStatus.csrfToken;
          axios.post('/alphax/status', { uploadId, token: "" }, {
            headers: {
              'X-CSRF-Token': csrfToken
            }})
            .then((response) => {
              if (response.data.allData) {
                localBoundingBoxesData[uploadId] = response.data;
                localBoundingBoxesData[uploadId].profileData.uploaded.reverse();
                setCachedBoundingBoxesData(localBoundingBoxesData);
              }
  
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                // Handle 403 Forbidden error
                console.error('Forbidden: Access to this resource is denied');
              } else {
                // Handle other errors
                console.error(error.message);
              }
            });
        }

      }
}

const processPreviewsCache = (url:string, id:string, almostDoneProcessing = false) => {
  if (!almostDoneProcessing) {
    const imageUrl = domain === "localhost" ? `https://dev.zencheck.ai/alphax/${id}/preview` : `/alphax/${id}/preview`;
    imageToBase64(imageUrl, (base64Image) => {
      if (base64Image) {
        localThumbnailCacheData[id] = true;
        localStorage.setItem(`localThumbnailCacheData`, JSON.stringify(localThumbnailCacheData));
        localThumbnailCache[id] = base64Image;
        try {
          localStorage.setItem(`preview-data-${id}`, base64Image);
        } catch (e) {
          console.error("err: ",e)
        }
        setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        
        //console.log(base64Image); // This is the base64-encoded string
      } else {
        console.error('Failed to convert image to base64');
      }
    });
  }
}

const processPDFCache = (url:string, id:string, almostDoneProcessing = false) => {
  console.log("here....")
  if (!localPDFCache[id]) {
    getProcessedData(id);
    fetchPDFWithAxios(url, function (pdfBlob:Blob) {
      const url = URL.createObjectURL(pdfBlob);
      localPDFCache[id] = url;
      setCachedPDFs(localPDFCache);
      
      console.log('Fetched PDF with Axios. Blob URL:', url);
      localPDFCacheData[id] = 1;

      

      //localThumbnailCache
      
      blobToBase64(pdfBlob).then(base64String => {
        localStorage.setItem(`localPDFCacheData`, JSON.stringify(localPDFCacheData));
        console.log("base64String: ",base64String,id);
        try {
          localStorage.setItem(`pdf-data-${id}`, base64String);
        } catch (e) {
          console.log("an error occured: ",e);
          if (domain !== "localhost") {
            const userData = localStorage.getItem('userData');
          localStorage.clear();
          if (userData) {
            localStorage.setItem('userData', userData);
          }
          }
          
        }
        
    });
      //localStorage.setItem(`pdf-data-${id}`, pdfBlob);
      setTimeout(() => setLoadedInitialData(true) , 1300);
      //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
      
  });
  } else {
    getProcessedData(id);
    if (Object.keys(cachedPDFs).length === 0)
    setCachedPDFs(localPDFCache);
  console.log({localPDFCache})
  if ((window as any).profileStatus) setLoadedInitialData(true);
    setTimeout(() => setLoadedInitialData(true) , 1300);
  }
}


  const preLoadData = (uploads: any[]) => {
    if (uploads.length === 0) setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
    console.log({uploads})
    if (uploads.length === 0) setTimeout(() => setLoadedInitialData(true) , 1300); //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
    for (let i = 0; i < (uploads.length > 5 ? 5 : uploads.length); i++) {
      processPDFCache(domain === "localhost" ? `https://dev.zencheck.ai/alphax/${uploads[i].id}/pdf` : `/alphax/${uploads[i].id}/pdf`,uploads[i].id, uploads[i].almostDoneProcessing);
    }

    //lets check if we have all data
    const maxCache = domain === "localhost" ? 10 : 20;
    let redownloadThumbs = false;
    for (let i = 0; i < (uploads.length > maxCache ? maxCache : uploads.length); i++) {
      if (!uploads[i].id) {
        if (i > 0 && i < 5) {
          redownloadThumbs = true;
          break;
        }
      }
    }

    if (Object.keys(localThumbnailCacheData).length) document.querySelector<HTMLElement>("#root")!.style.opacity = "1";

    for (let i = 0; i < (uploads.length > 25 ? 25 : uploads.length); i++) {
      processPreviewsCache(domain === "localhost" ? `https://dev.zencheck.ai/alphax/${uploads[i].id}/pdf` : `/alphax/${uploads[i].id}/pdf`,uploads[i].id, uploads[i].almostDoneProcessing);
    }
    
  }
  
  const checkUserProfile = () => {
    console.log("checking.....",window);


    if ((window as any).profileStatus) {
      console.log("using this...")
      //if ((window as any).profileStatus.ok) {
        if ((window as any).profileStatus.email) {
          console.log("using this... 2",(window as any).profileStatus.email)
          localStorage.setItem('userId', (window as any).profileStatus.email);
          const userData = (window as any).profileStatus.data;
          userData.uploaded.reverse();
          preLoadData(userData.uploaded);
          try {
            localStorage.setItem('userData', JSON.stringify(userData));
          } catch (e) {
            localStorage.clear();
            localStorage.setItem('userData', JSON.stringify(userData));
          }
          console.log({userData})
          setIsSignedIn(true);
          setUserId((window as any).profileStatus.email);
          console.log("seting true...")
          setLoadedInitialData(true);
          setCheckedLogin(true);
          document.querySelector<HTMLElement>("#root")!.style.opacity = "1";
          return;
          //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        }
      //} else {
       // signOut();
       // setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 2000);
      //}
      
    } else if (domain === "localhost") {
        axios.post('https://dev.zencheck.ai/alphax/profile-status-x', {}, {})
    .then((response) => {
      if (response.data.ok) {
        if (response.data.email) {
          localStorage.setItem('userId', response.data.email);
          const userData = response.data.data;
          userData.uploaded.reverse();
          preLoadData(userData.uploaded);
          try {
            localStorage.setItem('userData', JSON.stringify(userData));
          } catch (e) {
            localStorage.clear();
            localStorage.setItem('userData', JSON.stringify(userData));
          }
          setIsSignedIn(true)
          setUserId(response.data.email);
          //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        }
      } else {
        signOut();
        setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 2000);
      }
      setCheckedLogin(true);
      
    })
    .catch((error) => {
      console.log(error);
      setCheckedLogin(true)
      //setSignInError(true);
    });
      } else {

        console.log("sign out...")
        signOut();
        setCheckedLogin(true);
        document.querySelector<HTMLElement>("#root")!.style.opacity = "1";


        /*
        const domain = window.location.hostname;
      if (domain === "localhost") {
        axios.post('https://dev.zencheck.ai/alphax/profile-status-x', {}, {})
    .then((response) => {
      if (response.data.ok) {
        if (response.data.email) {
          localStorage.setItem('userId', response.data.email);
          const userData = response.data.data;
          userData.uploaded.reverse();
          preLoadData(userData.uploaded);
          localStorage.setItem('userData', JSON.stringify(userData));
          console.log({userData})
          setIsSignedIn(true);
          setUserId(response.data.email);
          //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        }
      } else {
        signOut();
        setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 2000);
      }
      setCheckedLogin(true);
      
    })
    .catch((error) => {
      console.log(error);
      setCheckedLogin(true)
      //setSignInError(true);
    });
      } else {
        axios.post('/alphax/profile-status', {}, { withCredentials: true})
    .then((response) => {
      if (response.data.ok) {
        if (response.data.email) {
          localStorage.setItem('userId', response.data.email);
          const userData = response.data.data;
          userData.uploaded.reverse();
          preLoadData(userData.uploaded);
          localStorage.setItem('userData', JSON.stringify(userData));
          console.log({userData})
          setIsSignedIn(true);
          setUserId(response.data.email);
          //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        }
      } else {
        signOut();
        setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 2000);
      }
      setCheckedLogin(true);
      
    })
    .catch((error) => {
      console.log(error);
      setCheckedLogin(true)
      //setSignInError(true);
    });
      }
        */
      
    }

    
/*
    axios.get(domain === "localhost" ? 'https://dev.zencheck.ai/csrf-token' : '/csrf-token').then(response => {
      if (response.data.csrfToken) {
        axios.defaults.headers.common['X-CSRF-Token'] = response.data.csrfToken;
        
        
      }
      

      
    });
*/

    
  };

  const getUserProfile = (token:string) => {
    if ((window as any).profileStatus && (window as any).profileStatus.csrfToken) {
      const csrfToken: AxiosHeaderValue = (window as any).profileStatus.csrfToken;
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/profile-info' : '/alphax/profile-info', {
        token
      }, {
        headers: {
          'X-CSRF-Token': csrfToken
        }})
        .then((response) => {
          if (response.data.ok) {
            const userData = response.data.data;
            userData.uploaded.reverse();
            preLoadData(userData.uploaded);
            try {
              localStorage.setItem('userData', JSON.stringify(userData));
            } catch (e) {
              localStorage.clear();
              localStorage.setItem('userData', JSON.stringify(userData));
            }
            setUserToken(response.data.token);
          } else {
            setSignInError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setSignInError(true);
        });
    }
    
  };

  const signIn = (email:string, password:string) => {
    setWaitingForResponse(true);
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/login/password' : '/login/password', {
      username: email,password
    }, {
      withCredentials: true
    })
      .then((response) => {
        if (response.data.ok) {
          localStorage.removeItem('uploadId')
          localStorage.setItem('userId', email);
          const userData = response.data.data;
          userData.uploaded.reverse();
          preLoadData(userData.uploaded);
          try {
            localStorage.setItem('userData', JSON.stringify(userData));
          } catch (e) {
            localStorage.clear();
            localStorage.setItem('userData', JSON.stringify(userData));
          }
          localStorage.setItem('token', response.data.token);
          setUserToken(response.data.token);
          setUserId(email);
          setIsSignedIn(true);
          //setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 1300);
        } else {
          setSignInError(true);
          setTimeout(() => document.querySelector<HTMLElement>("#root")!.style.opacity = "1", 2000);
        }
        setWaitingForResponse(false);
        
      })
      .catch((error) => {
        console.log(error);
        setWaitingForResponse(false);
      });
  };

  const signOut = () => {
    if (isSignedIn) {
      localStorage.removeItem('userId');
        localStorage.removeItem('uploadId');
        setUserId(null);
        setIsSignedIn(false);
      axios.get('/logout').then(response => {
        //localStorage.removeItem('userId');
        //localStorage.removeItem('uploadId');
        //window.location.reload();
        //setUserId(null);
        //setIsSignedIn(false);
      }).catch((error) => {
        console.log(error);
      });
    }
    

  }

  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack spacing={1} style={{position: "absolute", width: "100%", height: "100%", display: "none"}}>
        {
          userId === null ?
          <>
          {/* ZenCheck logo */}
          <Skeleton variant="rounded" width={260} height={50} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "calc(50% - 130px)", top: "100px"}} />
          {/* ZenCheck log in window */}
          <Skeleton variant="rounded" width={415} height={445} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "calc(50% - 207.5px)", top: "220px"}} />
          {/* ZenCheck copywright text */}
          <Skeleton variant="rounded" width={113} height={23} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "200px", bottom: "1.625rem"}} />
          </>
          :
          <>
          {/* ZenCheck logo */}
      <Skeleton variant="rounded" width={140} height={27} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "25px", top: "20px"}} />
      {/* ZenCheck `Dashboard` text */}
      <Skeleton variant="rounded" width={103} height={28} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "calc(50% - 54px)", top: "10px"}} />
      {/* New Analysis button */}
      <Skeleton variant="rounded" width={137.42} height={32} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", right: "85px", top: "10px"}} />
      {/* Settings button */}
      <Skeleton variant="rounded" width={64} height={32} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", right: "18px", top: "10px"}} />
      {/* Search bar */}
      <Skeleton variant="rounded" width={424} height={25} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "calc(50% - 212px)", top: "90px"}} />
      {/* Content */}
      <Skeleton variant="rounded" width={"calc(100% - 40px"} height={"calc(100% - 160px)"} animation="wave"  sx={{ bgcolor: '#0000001c' }} style={{position: "absolute", left: "20px", top: "140px"}} />
          </>
          
        }
        
    </Stack>
    <div className='inner-app'>
    {
        isSignedIn  && userId === "anesuemail@gmail." ?
        <Dev />
        : isSignedIn ? 
        <Presentation loadedInitialData={loadedInitialData} cachedPDFs={cachedPDFs} cachedBoundingBoxesData={cachedBoundingBoxesData} signOut={signOut} processPDFCache={processPDFCache} localThumbnailCache={localThumbnailCache} />
        : (isSignedIn && !checkedLogin) || !checkedLogin ? 
        null
        :
        <SignInBasic 
        signInError={signInError} 
        setSignInError={setSignInError} 
        waitingForResponse={waitingForResponse} 
        setWaitingForResponse={setWaitingForResponse}
        signIn={(username:string, password:string) => {signIn(username, password);/*setUserId(username); setPassword(password)*/}} 
        />
      }
    </div>
      
      
      </ThemeProvider>
    </div>
  );
}

export default App;
/*
{
  /*<div>
       <h3>Upload File</h3>
       <input type="file" onChange={handleFileUpload} />
       <button onClick={handleUpload}>Upload</button>
     </div>}
     */
