import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import BusinessIcon from '@mui/icons-material/Business';
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedIn from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import MKAlert from "components/MKAlert";
import SimpleFooter from "examples/Footers/SimpleFooter";
import { MdError } from "react-icons/md";
import { TailSpin } from 'react-loader-spinner'

// Images
import bgImageA from "assets/images/bg-new.jpg";

import bgImageMain from 'assets/login-bg.json';


import ZenCheckIconA from "assets/images/zencheck-logo-white.svg";
import mainLogo from 'assets/main-logo.json';
const ZenCheckIcon = mainLogo.mainLogo;
const bgImage = bgImageMain.loginBg;

const SignInBasic = ({signIn, signInError, setSignInError, waitingForResponse, setWaitingForResponse}) => {
  const [rememberMe, setRememberMe] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameErrror, setUsernameError] = useState(false);
  const [passwordErrror, setPasswordError] = useState(false);
  const [showPasswordFIeld, setShowPasswordFIeld] = useState(false);

  const domain = window.location.hostname;

  useEffect(() => {
    if (!rememberMe) {
      localStorage.removeItem('rememberMe');
    } else {
      localStorage.setItem('rememberMe',rememberMe);
    }
  }, []);

  const handleSetRememberMe = () => {
    if (rememberMe) {
      localStorage.removeItem('rememberMe');
    } else {
      localStorage.setItem('rememberMe',rememberMe);
    }
    setRememberMe(!rememberMe);
  }

  const updatePassword = (newPassword) => {
    if (passwordErrror) setPasswordError(false);
    if (signInError) setSignInError(false);
    setPassword(newPassword)
  }

  const updateUsername = (newUsername) => {
    if (usernameErrror) setUsernameError(false);
    if (passwordErrror) setPasswordError(false);
    if (signInError) setSignInError(false);
    setUsername(newUsername)
  }

  const submitUserDetails = () => {
    setWaitingForResponse(true);
    if (username === "") {
      setUsernameError(true);
      setWaitingForResponse(false);
      return;
    }
    
    if (username.indexOf("jamescowper.co.uk") !== -1) {
      window.location.href = "https://uk.app.zencheck.ai/auth/azureadoauth2";
    } else {
      console.log({username})
      if (password === "") setPasswordError(true);
      if (username !== "" && password !== "") document.getElementById("submit-btn").click();  //signIn(username, password);
      setWaitingForResponse(false);
    }
      

      
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'Enter') {
      event.preventDefault();
      submitUserDetails();
    }
  };

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="calc(100vh/var(--zoom-level))"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div id="background-blur"></div>
      <div className="signin-header">
      <img src={ZenCheckIcon}></img>
      </div>
      <MKBox px={1} width="100%" height="calc(100vh - 300px)" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MKTypography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href={domain === "localhost" ? "https://dev.zencheck.ai/auth/google" : "/auth/google"} variant="body1" color="white" title="Google Account">
                      <GoogleIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  {<Grid item xs={2}>
                    <MKTypography component={MuiLink} href={domain === "localhost" ? "https://dev.zencheck.ai/auth/linkedin" : "/auth/linkedin"} variant="body1" color="white" title="LinkedIn Account">
                      <LinkedIn color="inherit" />
                    </MKTypography>
                  </Grid>}
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href={domain === "localhost" ? "https://dev.zencheck.ai/auth/microsoft" : "/auth/microsoft"} variant="body1" color="white" title="Microsoft Personal Account">
                      <MicrosoftIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  {
                    signInError ?
                      <MKBox mb={2}>
                        <MKAlert color="light"><MdError size={90} style={{marginRight: "10px"}} /> <span> Invalid sign-in attempt. Please double-check your email and password.</span></MKAlert>
                      </MKBox>
                    :
                      null
                  }
                  
                  <MKBox mb={2}>
                    <MKInput disabled={waitingForResponse} error={usernameErrror} type="email" label="Email" onChange={(e) => {
                      updateUsername(e.target.value)
                    }} onKeyDown={(e) => handleKeyDown(e)} onInput={(e) => {
                      if (e.target.value.length && e.target.value.indexOf("@") !== -1 && e.target.value.indexOf(".") !== -1 && e.target.value[e.target.value.length - 1] !== "." && e.target.value.indexOf("jamescowper.co.uk") === -1) {
                        if (!showPasswordFIeld) setShowPasswordFIeld(true);
                      } else if (showPasswordFIeld) setShowPasswordFIeld(false);
                      updateUsername(e.target.value)}} fullWidth />
                  </MKBox>
                  {
                    showPasswordFIeld ?
                    <MKBox mb={2}>
                    <MKInput disabled={waitingForResponse} error={passwordErrror} onKeyDown={(e) => handleKeyDown(e)} type="password" label="Password" onInput={(e) => updatePassword(e.target.value)} fullWidth />
                  </MKBox>
                  :
                  null
                  }
                  
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton disabled={waitingForResponse} onClick={() => submitUserDetails()} variant="gradient" color="info" fullWidth>
                      {
                        waitingForResponse ?
                        <span style={{ marginRight: "10px" }}><TailSpin
                        visible={true}
                        height="20"
                        width="20"
                        color="white"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      /></span>
                      :
                      <span>sign in</span>
                      }
                      
                      
                    </MKButton>
                  </MKBox>
                  {/*<MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        to="/authentication/sign-up/cover"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MKTypography>
                    </MKTypography>
                  </MKBox>*/}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>

      {

      <form action="/login/password" method="post" style={{display: "none"}}>
    <section>
        <label for="username">Username</label>
        <input id="username" name="username" type="text" value={username} required />
    </section>
    <section>
        <label for="current-password">Password</label>
        <input id="current-password" name="password" type="password" value={password} required />
    </section>
    <button id="submit-btn" type="submit">Sign in</button>
</form>
      
      }

      
    </>
  );
}

export default SignInBasic;
